const actions = {
  GET_TASK_DETAIL_REQUEST: "TASK/GET_TASK_DETAIL_REQUEST",
  GET_TASK_DETAIL_SUCCESS: "TASK/GET_TASK_DETAIL_SUCCESS",
  GET_TASK_DETAIL_ERROR: "TASK/GET_TASK_DETAIL_ERROR",

  GET_TASK_METADATA_REQUEST: "TASK/GET_TASK_METADATA_REQUEST",
  GET_TASK_METADATA_SUCCESS: "TASK/GET_TASK_METADATA_SUCCESS",
  GET_TASK_METADATA_ERROR: "TASK/GET_TASK_METADATA_ERROR",

  GET_COMMENT_USER_NOTIFY_REQUEST: "TASK/GET_COMMENT_USER_NOTIFY_REQUEST",
  GET_COMMENT_USER_NOTIFY_SUCCESS: "TASK/GET_COMMENT_USER_NOTIFY_SUCCESS",
  GET_COMMENT_USER_NOTIFY_ERROR: "TASK/GET_COMMENT_USER_NOTIFY_ERROR",

  ADD_TASK_REQUEST: "TASK/ADD_TASK_REQUEST",
  ADD_TASK_SUCCESS: "TASK/ADD_TASK_SUCCESS",
  ADD_TASK_ERROR: "TASK/ADD_TASK_ERROR",

  EDIT_TASK_REQUEST: "TASK/EDIT_TASK_REQUEST",
  EDIT_TASK_SUCCESS: "TASK/EDIT_TASK_SUCCESS",
  EDIT_TASK_ERROR: "TASK/EDIT_TASK_ERROR",

  RESET_TASK_ADD_EDIT_STORE: "TASK/RESET_TASK_ADD_EDIT_STORE",

  GET_TASK_TASKLOG_REQUEST: "TASK/GET_TASK_TASKLOG_REQUEST",
  GET_TASK_TASKLOG_SUCCESS: "TASK/GET_TASK_TASKLOG_SUCCESS",
  GET_TASK_TASKLOG_ERROR: "TASK/GET_TASK_TASKLOG_ERROR",

  FETCH_TASK_TASKLOG_LATEST_REQUEST: "TASK/FETCH_TASK_TASKLOG_LATEST_REQUEST",
  FETCH_TASK_TASKLOG_LATEST_SUCCESS: "TASK/FETCH_TASK_TASKLOG_LATEST_SUCCESS",
  FETCH_TASK_TASKLOG_LATEST_ERROR: "TASK/FETCH_TASK_TASKLOG_LATEST_ERROR",

  EXECUTE_TASK_STOP_WATCH: "TASK/EXECUTE_TASK_STOP_WATCH",

  GET_TASK_TOTAL_TIMES_REQUEST: "TASK/GET_TASK_TOTAL_TIMES_REQUEST",
  GET_TASK_TOTAL_TIMES_SUCCESS: "TASK/GET_TASK_TOTAL_TIMES_SUCCESS",
  GET_TASK_TOTAL_TIMES_ERROR: "TASK/GET_TASK_TOTAL_TIMES_ERROR",

  GET_SUMMARIES_REQUEST: "TASK/GET_SUMMARIES_REQUEST",
  GET_SUMMARIES_SUCCESS: "TASK/GET_SUMMARIES_SUCCESS",
  GET_SUMMARIES_ERROR: "TASK/GET__SUMMARIES_ERROR",

  GET_COMMENTS_REQUEST: "TASK/GET_COMMENTS_REQUEST",
  GET_COMMENTS_SUCCESS: "TASK/GET_COMMENTS_SUCCESS",
  GET_COMMENTS_ERROR: "TASK/GET_COMMENTS_ERROR",

  CREATE_COMMENT_REQUEST: "TASK/CREATE_COMMENT_REQUEST",
  CREATE_COMMENT_SUCCESS: "TASK/CREATE_COMMENT_SUCCESS",
  CREATE_COMMENT_ERROR: "TASK/CREATE_COMMENT_ERROR",

  EDIT_COMMENT_REQUEST: "EDIT_COMMENT_REQUEST",
  EDIT_COMMENT_SUCCESS: "EDIT_COMMENT_SUCCESS",
  EDIT_COMMENT_ERROR: "EDIT_COMMENT_ERROR",

  GET_TASKS_CHILDREN_REQUEST: "TASK/GET_TASKS_CHILDREN_REQUEST",
  GET_TASKS_CHILDREN_SUCCESS: "TASK/GET_TASKS_CHILDREN_SUCCESS",
  GET_TASKS_CHILDREN_ERROR: "TASK/GET_TASKS_CHILDREN_ERROR",

  EDIT_TASKLOG_REQUEST: "TASK/EDIT_TASKLOG_REQUEST",
  EDIT_TASKLOG_SUCCESS: "TASK/EDIT_TASKLOG_SUCCESS",
  EDIT_TASKLOG_ERROR: "TASK/EDIT_TASKLOG_ERROR",

  TOGGLE_AMOUNT_GROUP_SELECT: "TOGGLE_AMOUNT_GROUP_SELECT",
  TOGGLE_AMOUNT_GROUP_PREVIEW: "TOGGLE_AMOUNT_GROUP_PREVIEW",

  SET_ITEM_PREVIEW: "SET_ITEM_PREVIEW",

  SET_TASK_MODE: "SET_TASK_MODE",
  SET_TASK: "SET_TASK",
  SET_STORE_SELECTED: "SET_STORE_SELECTED",
  RESET_TASK: "RESET_TASK",

  GET_LIST_USER: "GET_LIST_USER",
  GET_LIST_USER_SUCCESS: "GET_LIST_USER_SUCCESS",
  GET_LIST_USER_ERROR: "GET_LIST_USER_ERROR",

  GET_MEMBER_LIST_REQUEST: "TASK/GET_MEMBER_LIST_REQUEST",
  GET_MEMBER_LIST_SUCCESS: "TASK/GET_MEMBER_LIST_SUCCESS",
  GET_MEMBER_LIST_ERROR: "TASK/GET_MEMBER_LIST_ERROR",

  GET_OTA_LIST_REQUEST: "TASK/GET_OTA_LIST_REQUEST",
  GET_OTA_LIST_SUCCESS: "TASK/GET_OTA_LIST_SUCCESS",
  GET_OTA_LIST_ERROR: "TASK/GET_OTA_LIST_ERROR",

  GET_API_OTA_LIST_REQUEST: "TASK/GET_API_OTA_LIST_REQUEST",
  GET_API_OTA_LIST_SUCCESS: "TASK/GET_API_OTA_LIST_SUCCESS",
  GET_API_OTA_LIST_ERROR: "TASK/GET_API_OTA_LIST_ERROR",

  GET_CONTENT_LIST_REQUEST: "TASK/GET_CONTENT_LIST_REQUEST",
  GET_CONTENT_LIST_SUCCESS: "TASK/GET_CONTENT_LIST_SUCCESS",
  GET_CONTENT_LIST_ERROR: "TASK/GET_CONTENT_LIST_ERROR",

  GET_TASK_CONTENT_REQUEST: "TASK/GET_TASK_CONTENT_REQUEST",
  GET_TASK_CONTENT_SUCCESS: "TASK/GET_TASK_CONTENT_SUCCESS",
  GET_TASK_CONTENT_ERROR: "TASK/GET_TASK_CONTENT_ERROR",

  GET_TEAM_CONTENT_LIST_REQUEST: "TASK/GET_TEAM_CONTENT_LIST_REQUEST",
  GET_TEAM_CONTENT_LIST_SUCCESS: "TASK/GET_TEAM_CONTENT_LIST_SUCCESS",
  GET_TEAM_CONTENT_LIST_ERROR: "TASK/GET_TEAM_CONTENT_LIST_ERROR",

  GET_TEAM_EXECUTE_CONTENT_LIST_REQUEST: "TASK/GET_TEAM_EXECUTE_CONTENT_LIST_REQUEST",
  GET_TEAM_EXECUTE_CONTENT_LIST_SUCCESS: "TASK/GET_TEAM_EXECUTE_CONTENT_LIST_SUCCESS",
  GET_TEAM_EXECUTE_CONTENT_LIST_ERROR: "TASK/GET_TEAM_EXECUTE_CONTENT_LIST_ERROR",

  GET_TEAM_CONFIRM_CONTENT_LIST_REQUEST: "TASK/GET_TEAM_CONFIRM_CONTENT_LIST_REQUEST",
  GET_TEAM_CONFIRM_CONTENT_LIST_SUCCESS: "TASK/GET_TEAM_CONFIRM_CONTENT_LIST_SUCCESS",
  GET_TEAM_CONFIRM_CONTENT_LIST_ERROR: "TASK/GET_TEAM_CONFIRM_CONTENT_LIST_ERROR",

  FETCH_TASK_CONTENT_TASKLOG_LATEST_REQUEST: "TASK/FETCH_TASK_CONTENT_TASKLOG_LATEST_REQUEST",
  FETCH_TASK_CONTENT_TASKLOG_LATEST_SUCCESS: "TASK/FETCH_TASK_CONTENT_TASKLOG_LATEST_SUCCESS",
  FETCH_TASK_CONTENT_TASKLOG_LATEST_ERROR: "TASK/FETCH_TASK_CONTENT_TASKLOG_LATEST_ERROR",

  GET_TASK_CONTENT_TOTAL_TIMES_REQUEST: "TASK/GET_TASK_CONTENT_TOTAL_TIMES_REQUEST",
  GET_TASK_CONTENT_TOTAL_TIMES_SUCCESS: "TASK/GET_TASK_CONTENT_TOTAL_TIMES_SUCCESS",
  GET_TASK_CONTENT_TOTAL_TIMES_ERROR: "TASK/GET_TASK_CONTENT_TOTAL_TIMES_ERROR",

  EXECUTE_TASK_CONTENT_STOP_WATCH: "TASK/EXECUTE_TASK_CONTENT_STOP_WATCH",
  EXECUTE_TASK_CONTENT_STOP_WATCH_SUCCESS: "TASK/EXECUTE_TASK_CONTENT_STOP_WATCH_SUCCESS",
  EXECUTE_TASK_CONTENT_STOP_WATCH_ERROR: "TASK/EXECUTE_TASK_CONTENT_STOP_WATCH_ERROR",

  TASK_GET_MANUAL_FILE_REQUEST: "TASK/TASK_GET_MANUAL_FILE_REQUEST",
  TASK_GET_MANUAL_FILE_SUCCESS: "TASK/TASK_GET_MANUAL_FILE_SUCCESS",
  TASK_GET_MANUAL_FILE_ERROR: "TASK/TASK_GET_MANUAL_FILE_ERROR",

  EDIT_TASK_ASSIGN_REQUEST: "TASK/EDIT_TASK_ASSIGN_REQUEST",
  EDIT_TASK_ASSIGN_SUCCESS: "TASK/EDIT_TASK_ASSIGN_SUCCESS",
  EDIT_TASK_ASSIGN_ERROR: "TASK/EDIT_TASK_ASSIGN_ERROR",

  TASK_ATTACH_UPLOAD_BY_SUB_CATEGORY_REQUEST: "TASK_ATTACH_UPLOAD_BY_SUB_CATEGORY_REQUEST",
  TASK_ATTACH_UPLOAD_BY_SUB_CATEGORY_SUCCESS: "TASK_ATTACH_UPLOAD_BY_SUB_CATEGORY_SUCCESS",
  TASK_ATTACH_UPLOAD_BY_SUB_CATEGORY_ERROR: "TASK_ATTACH_UPLOAD_BY_SUB_CATEGORY_ERROR",

  UPDATE_TASK_MEMO_REQUEST: "TASK/UPDATE_TASK_MEMO_REQUEST",
  UPDATE_TASK_MEMO_SUCCESS: "TASK/UPDATE_TASK_MEMO_SUCCESS",
  UPDATE_TASK_MEMO_ERROR: "TASK/UPDATE_TASK_MEMO_ERROR",

  CREATE_WORK_SHEET_REQUEST: "TASK/CREATE_WORK_SHEET_REQUEST",
  CREATE_WORK_SHEET_SUCCESS: "TASK/CREATE_WORK_SHEET_SUCCESS",
  CREATE_WORK_SHEET_ERROR: "TASK/CREATE_WORK_SHEET_ERROR",

  RESET_TASK_DETAIL_REQUEST: "TASK/RESET_TASK_DETAIL_REQUEST",

  toggleTaskMode: () => ({
    type: actions.SET_TASK_MODE,
  }),

  getTaskDetail: (payload) => ({
    type: actions.GET_TASK_DETAIL_REQUEST,
    payload,
  }),

  getTaskMetadata: (payload) => ({
    type: actions.GET_TASK_METADATA_REQUEST,
    payload: payload,
  }),

  getCommentUserNotifyRequest: (payload) => ({
    type: actions.GET_COMMENT_USER_NOTIFY_REQUEST,
    payload: payload,
  }),

  createTask: (payload) => ({
    type: actions.ADD_TASK_REQUEST,
    payload: payload,
  }),

  editTask: (payload) => ({
    type: actions.EDIT_TASK_REQUEST,
    payload: payload,
  }),

  getComments: (payload) => ({
    type: actions.GET_COMMENTS_REQUEST,
    payload: payload,
  }),

  createComment: (payload) => ({
    type: actions.CREATE_COMMENT_REQUEST,
    payload: payload,
  }),

  getTaskLogs: (payload) => ({
    type: actions.GET_TASK_TASKLOG_REQUEST,
    payload,
  }),

  getSummaries: (payload) => ({
    type: actions.GET_SUMMARIES_REQUEST,
    payload,
  }),

  getTaskChildren: (payload) => ({
    type: actions.GET_TASKS_CHILDREN_REQUEST,
    payload,
  }),

  fetchTaskLogsLatest: (payload) => ({
    type: actions.FETCH_TASK_TASKLOG_LATEST_REQUEST,
    payload,
  }),

  executeTaskStopWatch: (payload) => ({
    type: actions.EXECUTE_TASK_STOP_WATCH,
    payload,
  }),

  getTaskTotalTimes: (payload) => ({
    type: actions.GET_TASK_TOTAL_TIMES_REQUEST,
    payload,
  }),

  editTaskLog: (payload) => ({
    type: actions.EDIT_TASKLOG_REQUEST,
    payload,
  }),

  toggleAmountGroupSelect: () => ({
    type: actions.TOGGLE_AMOUNT_GROUP_SELECT,
  }),

  toggleAmountGroupPreview: () => ({
    type: actions.TOGGLE_AMOUNT_GROUP_PREVIEW,
  }),

  setItemPreview: (payload) => ({
    type: actions.SET_ITEM_PREVIEW,
    payload,
  }),

  setTask: () => ({
    type: actions.SET_TASK,
  }),
  setStoreSelected: (payload) => ({
    type: actions.SET_STORE_SELECTED,
    payload,
  }),

  resetTask: () => ({
    type: actions.RESET_TASK,
  }),

  editComment: (payload) => ({
    type: actions.EDIT_COMMENT_REQUEST,
    payload,
  }),

  getListUser: (payload)=> ({
    type: actions.GET_LIST_USER,
    payload,
  }),

  getMemberList: (payload)=> ({
    type: actions.GET_MEMBER_LIST_REQUEST,
    payload,
  }),

  getOtaList: (payload)=> ({
    type: actions.GET_OTA_LIST_REQUEST,
    payload,
  }),

  getApiOtaList: (payload)=> ({
    type: actions.GET_API_OTA_LIST_REQUEST,
    payload,
  }),

  getContentList: (payload)=> ({
    type: actions.GET_CONTENT_LIST_REQUEST,
    payload,
  }),

  getTeamContentList: (payload)=> ({
    type: actions.GET_TEAM_CONTENT_LIST_REQUEST,
    payload,
  }),

  getTeamExecuteContentList: (payload)=> ({
    type: actions.GET_TEAM_EXECUTE_CONTENT_LIST_REQUEST,
    payload,
  }),

  getTeamConfirmContentList: (payload)=> ({
    type: actions.GET_TEAM_CONFIRM_CONTENT_LIST_REQUEST,
    payload,
  }),

  getTaskContents: (payload) => ({
    type: actions.GET_TASK_CONTENT_REQUEST,
    payload,
  }),

  fetchTaskContentLogsLatest: (payload) => ({
    type: actions.FETCH_TASK_CONTENT_TASKLOG_LATEST_REQUEST,
    payload,
  }),

  getTaskContentTotalTimes: (payload) => ({
    type: actions.GET_TASK_CONTENT_TOTAL_TIMES_REQUEST,
    payload,
  }),
  executeTaskContentStopWatch: (payload) => ({
    type: actions.EXECUTE_TASK_CONTENT_STOP_WATCH,
    payload,
  }),
  getManualFile: (payload) => ({
    type: actions.TASK_GET_MANUAL_FILE_REQUEST,
    payload,
  }),

  editTaskAssign: (payload) => ({
    type: actions.EDIT_TASK_ASSIGN_REQUEST,
    payload: payload,
  }),

  updateTaskMemo: (payload) => ({
    type: actions.UPDATE_TASK_MEMO_REQUEST,
    payload: payload,
  }),

  taskAttachUploadBySubCategory: (payload)  => ({ type: actions.TASK_ATTACH_UPLOAD_BY_SUB_CATEGORY_REQUEST, payload }),

  createWorkSheet: (payload) => ({
    type: actions.CREATE_WORK_SHEET_REQUEST,
    payload: payload,
  }),

  resetTaskDetail: () => ({
    type: actions.RESET_TASK_DETAIL_REQUEST,
  }),
};

export default actions;
