import React, { memo, useState, useEffect, useCallback } from "react";
import {Col, Row, Button, Modal, Checkbox, Empty} from "antd";
import { Form, Input } from "formik-antd";
import { CloseOutlined } from '@ant-design/icons';
import { useIntl } from "react-intl";
import { UploadFileRoomStyle } from "./UploadFileRoomStyle";
import { isEmpty } from "lodash";
import {ValidateField} from "@iso/validates/ValidateField";
import { IMAGE_DEFAULT } from "@iso/constants/api.constant";
import { MODE_SCREEN } from "@iso/constants/common.constant";

const UploadFile = ({
    values,
    dataOta,
    setFieldValue,
    tabKey,
    apiRoomId,
    imageByStoreAndOta,
    dataRoom,
    isHiddenUrl,
    titleCustom,
    mode
}) => {
    const { messages } = useIntl();
    const [previewOpenImageStorage, setPreviewOpenImageStorage] = useState(false);
    const [previewImage, setPreviewImage] = useState([]);
    const [arrayIdImageCreate, setArrayIdImageCreate] = useState([]);
    const [arrayIdImageDelete, setArrayIdImageDelete] = useState([]);
    const [urlImage, setUrlImage] = useState([]);
    const [previewTitle, setPreviewTitle] = useState('');
    const handleCancelModalImageStorage = () => setPreviewOpenImageStorage(false);
    const [valueCheckboxImageStorage, setValueCheckboxImageStorage] = useState([])
    const [validImages, setValidImages] = useState({});

    useEffect(() => {
        if (apiRoomId && !isEmpty(dataRoom) && !isEmpty(dataRoom.apiRoomOtas[tabKey])) {
            let arrayImageStorage = dataRoom.apiRoomOtas[tabKey].imageStorage;
            let arrayImageUrl = dataRoom.apiRoomOtas[tabKey].imageUrl;

            setPreviewImage(arrayImageStorage);

            let arrayIdImage = []
            arrayImageStorage.map((image, index) => {
                arrayIdImage.push(image.imageOtaId)
            })
            setValueCheckboxImageStorage(arrayIdImage)

            if (mode == MODE_SCREEN.COPY) {
                setArrayIdImageCreate(arrayIdImage)
                setFieldValue(`apiRoomOtas[${tabKey}]['arrayImageCreate']`, arrayIdImage)
            }

            if (arrayImageUrl !== null && arrayImageUrl !== undefined && arrayImageUrl.length > 0) {
                setUrlImage(arrayImageUrl)
            }
        }
    }, [dataRoom, mode]);

    useEffect(() => {
        if (!isEmpty(values) && !isEmpty(values.apiRoomOtas[tabKey])) {
            let arrayUrl = values.apiRoomOtas[tabKey].imageUrl
            if (isEmpty(arrayUrl)) {
                setFieldValue(`apiRoomOtas[${tabKey}][imageUrl]`, [{
                    apiRoomOtaImageId: '',
                    url: null,
                }])
            } else {
                setUrlImage(arrayUrl)
            }
        }
    }, [values.apiRoomOtas[tabKey]])

    useEffect(() => {
        validateImages();
    }, [urlImage])

    const validateImages = async () => {
        if (!isEmpty(urlImage) && urlImage.length > 0) {
            const validationResults = await Promise.all(
                urlImage.map(async (image, index) => {
                    const isValid = await checkImageValid(image.url);
                    return { index, isValid };
                })
            );

            const validImagesMap = validationResults.reduce((acc, { index, isValid }) => {
                acc[index] = isValid;
                return acc;
            }, {});

            setValidImages(validImagesMap);
        }
    };

    const onChangeCheckboxImageStorage = useCallback((e) => {
        let imageOtaIdChecked = e.target.value
        if (e.target.checked == true) {
            setValueCheckboxImageStorage([...valueCheckboxImageStorage, imageOtaIdChecked])

            let findDetailImage = imageByStoreAndOta.find((image) => image.apiImageOtaId == imageOtaIdChecked);
            if (findDetailImage) {
                let newImage = {
                    'apiRoomOtaImageId': null,
                    'imageOtaId': findDetailImage.apiImageOtaId,
                    'name': findDetailImage.apiImageOtaId + "_" + findDetailImage.roomOtaId + ".png",
                    'roomOtaId': findDetailImage.roomOtaId,
                    's3Url': findDetailImage.s3Url,
                    'type': 1,
                    'url': null
                }

                setPreviewImage([...previewImage, newImage])
            }

            if (apiRoomId && !isEmpty(values) && !isEmpty(values.apiRoomOtas[tabKey]) && (mode != MODE_SCREEN.COPY)) {
                let arrayImageStorage = values.apiRoomOtas[tabKey].imageStorage;
                let checkImageExistInArray = arrayImageStorage.find(image => image.imageOtaId == imageOtaIdChecked);
                if (!checkImageExistInArray) {
                    setArrayIdImageCreate([...arrayIdImageCreate, imageOtaIdChecked]);
                    setFieldValue(`apiRoomOtas[${tabKey}]['arrayImageCreate']`, [...arrayIdImageCreate, imageOtaIdChecked])
                } else {
                    let arrayValueCheckedDelete = [...arrayIdImageDelete];
                    const index = arrayValueCheckedDelete.indexOf(checkImageExistInArray.apiRoomOtaImageId);
                    if (index > -1) {
                        arrayValueCheckedDelete.splice(index, 1);
                    }

                    setArrayIdImageDelete(arrayValueCheckedDelete);
                    setFieldValue(`apiRoomOtas[${tabKey}]['arrayImageDelete']`, arrayValueCheckedDelete)
                }
            } else {
                setArrayIdImageCreate([...arrayIdImageCreate, imageOtaIdChecked]);
                setFieldValue(`apiRoomOtas[${tabKey}]['arrayImageCreate']`, [...arrayIdImageCreate, imageOtaIdChecked])
            }
        } else {
            let arrayPreviewImage = [...previewImage];
            let arrayValueCheckedImage = [...valueCheckboxImageStorage];
            const index = arrayValueCheckedImage.indexOf(imageOtaIdChecked);
            if (index > -1) {
                arrayValueCheckedImage.splice(index, 1);
            }

            setValueCheckboxImageStorage(arrayValueCheckedImage);

            var indexPreviewImage = arrayPreviewImage.map(imagePreview => {
                return imagePreview.imageOtaId;
            }).indexOf(imageOtaIdChecked);

            arrayPreviewImage.splice(indexPreviewImage, 1)
            setPreviewImage(arrayPreviewImage)

            if (apiRoomId && !isEmpty(values) && !isEmpty(values.apiRoomOtas[tabKey]) && (mode != MODE_SCREEN.COPY)) {
                let arrayImageStorage = values.apiRoomOtas[tabKey].imageStorage;
                let checkImageExistInArray = arrayImageStorage.find(image => image.imageOtaId == imageOtaIdChecked);
                if (!checkImageExistInArray) {
                    let arrayValueCheckedCreate = [...arrayIdImageCreate];
                    const index = arrayValueCheckedCreate.indexOf(imageOtaIdChecked);
                    if (index > -1) {
                        arrayValueCheckedCreate.splice(index, 1);
                    }

                    setArrayIdImageCreate(arrayValueCheckedCreate);
                    setFieldValue(`apiRoomOtas[${tabKey}]['arrayImageCreate']`, arrayValueCheckedCreate)
                } else {
                    setArrayIdImageDelete([...arrayIdImageDelete, checkImageExistInArray.apiRoomOtaImageId]);
                    setFieldValue(`apiRoomOtas[${tabKey}]['arrayImageDelete']`, [...arrayIdImageDelete, checkImageExistInArray.apiRoomOtaImageId])
                }
            } else {
                let arrayValueCheckedCreate = [...arrayIdImageCreate];
                const index = arrayValueCheckedCreate.indexOf(imageOtaIdChecked);
                if (index > -1) {
                    arrayValueCheckedCreate.splice(index, 1);
                }

                setArrayIdImageCreate(arrayValueCheckedCreate);
                setFieldValue(`apiRoomOtas[${tabKey}]['arrayImageCreate']`, arrayValueCheckedCreate)
            }
        }
    }, [valueCheckboxImageStorage, arrayIdImageCreate, arrayIdImageDelete, imageByStoreAndOta]);

    const removeImage = (idImage) => {
        let arrayPreviewImage = [...previewImage];
        let arrayValueCheckedImage = [...valueCheckboxImageStorage];
        const index = arrayValueCheckedImage.indexOf(idImage);
        if (index > -1) {
            arrayValueCheckedImage.splice(index, 1);
        }

        setValueCheckboxImageStorage(arrayValueCheckedImage);

        var indexPreviewImage = arrayPreviewImage.map(imagePreview => {
            return imagePreview.imageOtaId;
        }).indexOf(idImage);

        arrayPreviewImage.splice(indexPreviewImage, 1)
        setPreviewImage(arrayPreviewImage)

        if (apiRoomId && !isEmpty(values) && !isEmpty(values.apiRoomOtas[tabKey]) && (mode != MODE_SCREEN.COPY)) {
            let arrayImageStorage = values.apiRoomOtas[tabKey].imageStorage;
            let checkImageExistInArray = arrayImageStorage.find(image => image.imageOtaId == idImage);
            if (!checkImageExistInArray) {
                let arrayValueCheckedCreate = [...arrayIdImageCreate];
                const index = arrayValueCheckedCreate.indexOf(idImage);
                if (index > -1) {
                    arrayValueCheckedCreate.splice(index, 1);
                }

                setArrayIdImageCreate(arrayValueCheckedCreate);
                setFieldValue(`apiRoomOtas[${tabKey}]['arrayImageCreate']`, arrayValueCheckedCreate)
            } else {
                setArrayIdImageDelete([...arrayIdImageDelete, checkImageExistInArray.apiRoomOtaImageId]);
                setFieldValue(`apiRoomOtas[${tabKey}]['arrayImageDelete']`, [...arrayIdImageDelete, checkImageExistInArray.apiRoomOtaImageId])
            }
        } else {
            let arrayValueCheckedCreate = [...arrayIdImageCreate];
            const index = arrayValueCheckedCreate.indexOf(idImage);
            if (index > -1) {
                arrayValueCheckedCreate.splice(index, 1);
            }

            setArrayIdImageCreate(arrayValueCheckedCreate);
            setFieldValue(`apiRoomOtas[${tabKey}]['arrayImageCreate']`, arrayValueCheckedCreate)
        }
    }

    const addElementImageUrl = () => {
        let arrayUrlImage = [...urlImage, {
            apiRoomOtaImageId: '',
            url: null
        }];
        setUrlImage(arrayUrlImage);
        setFieldValue(`apiRoomOtas[${tabKey}][imageUrl]`, arrayUrlImage);
    }

    const deleteElementImageUrl = (id, index) => {
        let arrayUrlImage = [...urlImage];
        arrayUrlImage.splice(index, 1);
        setUrlImage(arrayUrlImage);
        setFieldValue(`apiRoomOtas[${tabKey}]['imageUrl']`, arrayUrlImage)
        
        if (id) {
            setArrayIdImageDelete([...arrayIdImageDelete, id]);
            setFieldValue(`apiRoomOtas[${tabKey}]['arrayImageDelete']`, [...arrayIdImageDelete, id])
        }
    }

    const checkImageValid = (url) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = url;
        });
    };

    return (
        <UploadFileRoomStyle>
            <Row>
                <Col xs={24} lg={{ span: 24 }} style={{ marginBottom: '20px' }}>
                    <span className={"custom-title label-custom"}>{titleCustom ? titleCustom : messages["page.room.ota.image"]}</span>
                </Col>
            </Row>
            <div style={{marginLeft: '30px'}}>
                <Row>
                    <Col xs={24} lg={{ span: 24 }} className="mb-10">
                        <span className={"custom-title label-custom"}>{messages["page.room.ota.image.uploadOrStore"]}</span>
                    </Col>
                </Row>
                <Row>
                    {previewImage.length > 0 && (
                        <Col xs={24} lg={{ span: 24 }} className="mb-10">
                            <Row>
                                {previewImage.map((image, key) => {
                                    return (
                                        <Col key={key} xs={24} lg={{ span: 3 }}>
                                            <div className="container" style={{ padding: '10px', position: 'relative' }}>
                                                <img
                                                    className="image"
                                                    alt={image.name}
                                                    style={{
                                                        width: '100%',
                                                        height: '150px',
                                                        objectFit: 'cover'
                                                    }}
                                                    src={image.s3Url}
                                                />

                                                <div className="middle">
                                                    <button
                                                        className="icon-remove"
                                                        onClick={() => removeImage(image.imageOtaId)}
                                                        type="button"
                                                    >
                                                        <CloseOutlined />
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col xs={24} lg={{ span: 10 }} style={{ marginBottom: '10px' }}>
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['image']`}
                        >
                            <Button  type="primary"
                                     htmlType="button"
                                     onClick={() => setPreviewOpenImageStorage(true)}>
                                {messages["page.btn.choose"]}
                            </Button>
                            <Modal visible={previewOpenImageStorage} title={previewTitle} footer={null} onCancel={handleCancelModalImageStorage}>
                                <Checkbox.Group
                                    name={`apiRoomOtas[${tabKey}]['imageStorage]`}
                                    value={valueCheckboxImageStorage}
                                    style={{display: 'flex', justifyContent: 'center'}}
                                >
                                    {imageByStoreAndOta.length <= 0 && (
                                        <Row  style={{ padding: '10px' }}>
                                            <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                description={
                                                    <span>{messages["antTable.emptyData"]}</span>
                                                }
                                            >
                                            </Empty>
                                        </Row>
                                    )}
                                    <Row style={{ padding: '10px',maxHeight: '80vh', overflowY: 'auto' ,marginTop: '20px' }}>
                                        {imageByStoreAndOta.map((image, key) => {
                                            if (image.otaId == dataOta.otaId) {
                                                return (
                                                    <Col xs={24} lg={{ span: 12 }} key={key}>
                                                        <div style={{ padding: '10px' }}>
                                                            <Checkbox
                                                                value={image.apiImageOtaId}
                                                                style={{ position: 'absolute', left: '20px', top: '15px' }}
                                                                onChange={(e) => { onChangeCheckboxImageStorage(e) }}
                                                            />
                                                            <div>
                                                                <img
                                                                    alt={image.name}
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '200px',
                                                                        objectFit: 'cover'
                                                                    }}
                                                                    src={image.s3Url}
                                                                />

                                                                <div>
                                                                    {image.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )
                                            }
                                        })}
                                    </Row>
                                </Checkbox.Group>
                            </Modal>
                        </Form.Item>
                    </Col>
                </Row>
                <div hidden={isHiddenUrl}>
                    <Row>
                        <Col className="mb-10">
                            <span className={"custom-title"}>{messages["page.room.uploadImage.url"]}</span>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '20px' }}>
                        <Col xs={24} lg={{ span: 8 }} style={{ maxHeight: '55.2vh', overflowY: 'auto' }}>
                            {(!isEmpty(urlImage) && urlImage.length > 0) &&
                                urlImage.map((image, index) => (
                                    <Row key={index} style={{ marginBottom: '20px' }}>
                                        <Col lg={16} xs={16}>
                                            <Form.Item
                                                name={`apiRoomOtas[${tabKey}]['imageUrl'][${index}]['url']`}
                                                validate={(value) => ValidateField(
                                                    value,
                                                    messages["page.room.uploadImage.url"],
                                                    {
                                                        required: messages["form.error.mixed.required"],
                                                        valueInvalid: messages["page.room.valueInvalid"]
                                                    },
                                                    {
                                                        space: true,
                                                        imageUrl: true
                                                    }
                                                )}
                                            >
                                                <Input
                                                    name={`apiRoomOtas[${tabKey}]['imageUrl'][${index}]['url']`}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={8} xs={8}>
                                            <Button
                                                type="dashed"
                                                disabled={urlImage.length <= 1}
                                                style={{ marginRight: 4, marginLeft: 4 }}
                                                onClick={() => deleteElementImageUrl(image.apiRoomOtaImageId, index)}
                                            >
                                                -
                                            </Button>
                                            <Button
                                                type="dashed"
                                                onClick={() => addElementImageUrl()}
                                            >
                                                +
                                            </Button>
                                        </Col>

                                        <Col lg={24} xs={24}>
                                            <div className="container" style={{ padding: "10px" }}>
                                                <img
                                                    alt={image.apiRoomOtaImageId}
                                                    style={{
                                                        width: '100px',
                                                        height: '100px',
                                                        objectFit: 'cover',
                                                        borderRadius: "10px"
                                                    }}
                                                    src={validImages[index] ? image.url : IMAGE_DEFAULT}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </UploadFileRoomStyle>
    )
}

export default memo(UploadFile);
